<template>
  <div class="recipe-detail-filter ma-2 mt-4" v-if="coursesData">
    <v-select
      outlined
      hide-details
      single-line
      return-object
      clearable
      v-model="course"
      :items="coursesData"
      item-text="name"
      item-value="slug"
      :label="$t('recipes.filter.portata')"
      class="white default--text mb-3 mb-md-0"
      @change="filterByCourse(course)"
    >
    </v-select>
  </div>
</template>
<style lang="scss"></style>

<script>
import CMSService from "~/service/cmService";

export default {
  name: "RecipeFilter",
  data() {
    return {
      course: null,
      coursesData: null
    };
  },
  props: {
    changePage: { type: String, default: "true" }
  },
  methods: {
    async fetchWpDataCourse() {
      const result = await CMSService.getCustomPostBySlug("course");
      if (result && result.length) {
        return result;
      }
    },
    filterByCourse(course) {
      let query = {};
      if (course) {
        query["course"] = course.slug;
      }
      this.$router.push({ name: "AppuntinoList", query });
    }
  },
  async mounted() {
    this.coursesData = await this.fetchWpDataCourse();
  }
};
</script>
